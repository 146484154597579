<template>
  <div class="realName">
    <p class="requirements">请上传身份证正反面照片</p>
    <p class="title">
      <img src="../../assets/my/anquanyinsi@2x.png" alt />
      <span>根据监管要求身份证照片仅用于实名认证</span>
    </p>
    <div class="up uplond-box">
      <div class="uplond-box-left">
        <p class="uplond-box-left-title">头像面</p>
        <p class="uplond-box-left-describe">点击上传头像面</p>
      </div>
      <van-uploader
        accept="image/*"
        :after-read="afterRead(itm,1)"
        file-list="{{ fileList }}"
        preview-size="192px"
        class="up uplond-box"
        v-model="fileList"
        :max-count="1"
        max-size="5000000"
        @oversize="onOversize"
      >
        <img class="uplond-box-right" src="../../assets/my/up@2x.png" alt />
        <template #preview-cover="{ fileList }">
          <img v-if="file" class="uplond-box-right" :src="fileList[0].src" alt />
        </template>
      </van-uploader>
    </div>
    <div class="down uplond-box">
      <div class="uplond-box-left">
        <p class="uplond-box-left-title">国徽面</p>
        <p class="uplond-box-left-describe">点击上传国徽面</p>
      </div>
      <van-uploader
        accept="image/*"
        :after-read="afterRead(itm,2)"
        file-list="{{ fileList2 }}"
        preview-size="192px"
        class="up uplond-box"
        v-model="fileList2"
        :max-count="1"
        max-size="5000000"
        @oversize="onOversize"
      >
        <img class="uplond-box-right" src="../../assets/my/down@2x.png" alt />
        <template #preview-cover="{ fileList2 }">
          <img v-if="file" class="uplond-box-right" :src="fileList2[0].src" alt />
        </template>
      </van-uploader>
    </div>
    <van-field
      :label-width="76"
      v-model="nickname"
      style="font-family: HelveticaCondensedBold, HelveticaCondensed;"
      label="真实姓名"
      placeholder="请输入真实姓名"
    />
    <van-field
      :label-width="76"
      v-model="idNum"
      style="font-family: HelveticaCondensedBold, HelveticaCondensed;"
      label="身份证号"
      maxlength="18"
      placeholder="请输入身份证号"
    />
    <van-field v-show="false" />
    <van-button
      @click="toSubmit()"
      :disabled="nickname == '' || idNum == ''|| fileList == ''|| fileList2 == ''"
      style="font-family: HelveticaCondensedBold, HelveticaCondensed;width:100%;margin-top:20px;font-size: 18px;font-weight: 700;color: #FFFFFF;line-height: 25px;"
      type="primary"
    >提交</van-button>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "realName",
  data() {
    return {
      isImg1:1, //1上传失败，2上传中，3上传成功
      isImg2:1,//1上传失败，2上传中，3上传成功
      nickname: "",
      idNum: "",
      fileList: [],
      fileList2: [],
      url: ""
    };
  },
  methods: {
    toSubmit() {
      console.log("图片地址：1",this.fileList[0].src)
      console.log("图片地址：2",this.fileList2[0].src)
      if(this.isImg1 === 1){
        Toast('头像面失败，请重新上传');
        this.fileList[0].src = ''
        return 
      }
      if( this.isImg1 === 2 ){
        Toast('头像面上传中，请等待');
        return
      }
      if(this.isImg2 === 1 ){
        Toast('国徽面上传失败，请重新上传');
        this.fileList2[0].src = ''
        return
      }
      if(this.isImg2 === 2){
        Toast('国徽面上传中，请等待');
        return 
      }
      const params = {
        nickname: this.nickname,
        idNum: this.idNum,
        identityFront: this.fileList[0].src,
        identityBack: this.fileList2[0].src
      };
      this.$request.post("/user/auth", params).then(
        res => {
          if (res.code === 0) {
            this.$toast.success({
              message: "提交成功",
              duration: 1000
            });
            setTimeout(() => {
              this.$router.push({ path: "/my" });
            }, 1000);
          } else {
            Toast.fail(res.msg);
            if (res.code == 1018) {
              this.$router.push("/login");
            }
          }
          // 执行成功的回调函数
        },
        error => {
          console.log(error);
          // 执行失败的回调函数
        }
      );
    },
    onOversize() {
      Toast("上传图片不能超过5M");
    },
    afterRead(itm, type) {
      console.log(this.fileList[0])
      console.log(this.fileList2)
      console.log(itm,'itm')
      // 上传中
          return file => {
          let formData = new FormData();
          formData.append("file", file.file);
          console.log(file.content,'file')
          if(type===1){
            this.isImg1 = 2
          }else{
            this.isImg2 = 2
          }
          this.$request.post("/common/upload", formData).then(
            res => {
              if(res.code !==200){
                if(type===1){
                  this.isImg1 = 1
                }else if(type===2){
                  this.isImg2 = 1
                }
              }
              if (res.code === 200) {
                if(type===1){
                  this.fileList[0].src = res.data.fileUrl;
                  this.isImg1 = 3
                }else if(type===2){
                  this.fileList2[0].src = res.data.fileUrl;
                  this.isImg2 = 3
                }
              } else if (res.code === 1002) {
                Toast.fail(res.msg);
              }
              if (res.code == 1018) {
                this.$router.push("/login");
              }
              // 执行成功的回调函数
            },
            error => {
              if(type===1){
                  this.isImg1 = 1
                }else if(type===2){
                  this.isImg2 = 1
                }
              console.log(error);
              // 执行失败的回调函数
            }
          );
        };
      }
      // }
      // return file => {
      //   let formData = new FormData();
      //   formData.append("file", file.file);
      //   console.log(file.content,'file')
      //   this.$request.post("/common/upload", formData).then(
      //     res => {
      //       if (res.code === 200) {
      //         if (type === 1) {
      //           this.fileList[0].src = res.data.fileUrl;
      //           this.isImg1 = true
      //         } else if (type === 2) {
      //           this.fileList2[0].src = res.data.fileUrl;
      //           this.isImg2 = true
      //         }
      //       } else if (res.code === 1002) {
      //         Toast.fail(res.msg);
      //       }
      //       if (res.code == 1018) {
      //         this.$router.push("/login");
      //       }
      //       // 执行成功的回调函数
      //     },
      //     error => {
      //       console.log(error);
      //       // 执行失败的回调函数
      //     }
      //   );
      // };
    // }
  }
};
</script>

<style lang="scss" scoped>
.realName {
  text-align: left;
  width: 100%;
  padding: 25px 20px 30px;
  .requirements {
    font-size: 22px;
    font-weight: 700;
    color: #333333;
    line-height: 30px;
    font-family: "HelveticaCondensedBold", "HelveticaCondensed";
  }
  .title {
    margin: 5px 0 35px;
    font-size: 13px;
    font-weight: 400;
    color: #93a5b2;
    display: flex;
    align-items: center;
    font-family: "HelveticaCondensedBold", "HelveticaCondensed";
    img {
      width: 12px;
      margin-right: 3px;
      margin-top: 1px;
    }
  }
  .uplond-box {
    // width: 100%;
    height: 150px;
    background: #f5f6f9;
    border-radius: 6px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .uplond-box-left {
      width: 134px;
      .uplond-box-left-title {
        margin: 35px 0 5px 20px;
        font-size: 17px;
        font-weight: 700;
        color: #333333;
        line-height: 24px;
        font-family: "HelveticaCondensedBold", "HelveticaCondensed";
      }
      .uplond-box-left-describe {
        margin-left: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #777777;
        line-height: 17px;
      }
    }
    .preview-cover {
      width: 192px;
      height: 132px;
    }
    .uplond-box-right {
      width: 192px;
      height: 132px;
      overflow: hidden;
    }
  }
  .van-cell {
    margin: 10px;
    padding: 15px 0px;
    font-weight: 700;
    .van-field__label {
    }
  }
  .van-cell::after {
    left: 0px;
    right: 0px;
  }
  .van-button--disabled {
    background: #d7d8e1;
    opacity: 1;
    border-color: #d7d8e1;
  }
}
</style>

<style lang="scss">
.van-field__label {
  color: #4a4a4a;
  font-size: 14px;
}

::placeholder {
  font-size: 14px;
  color: #d7d8e1;
}
.van-uploader__wrapper {
  width: 192px;
  height: 132px;
  margin: 9px 5px 9px 0;
}
.van-uploader__preview {
  width: 192px;
  height: 132px;
  overflow: hidden;
}

.van-uploader__preview-image {
  width: 192px;
  height: 132px;
}
</style>
